import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingElement } from "../components/LoadingElement/LoadingElement.tsx";
import {
  AllScreeningsOfOneFilm,
  ScreeningsOnOneDate,
} from "../components/../types/types.ts";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchScreenings } from "../helpers/onCallFunctions.ts";
import { AllFilmsOnOneTab } from "../components/AllFilmsOnOneTab/AllFilmsOnOneTab.tsx";
import { useTheme } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1.5, md: 3 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FilmListings = () => {
  const [queryStringParameters] = useSearchParams();
  const [value, setValue] = React.useState(0);
  const [filmsByDate, setFilmsByDate] = React.useState<ScreeningsOnOneDate[]>(
    []
  );
  const [allFilmsOnAllDates, setAllFilmsOnAllDates] = React.useState<
    AllScreeningsOfOneFilm[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  // eslint-disable-next-line
  const [error, setError] = React.useState(null);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  React.useEffect(() => {
    const fetchScreeningsData = async () => {
      try {
        const venues = queryStringParameters.getAll("venues");
        const { screeningsByDate, allScreeningsOnAllDays } =
          await fetchScreenings(venues);
        setFilmsByDate(screeningsByDate);
        setAllFilmsOnAllDates(allScreeningsOnAllDays);
      } catch (error) {
        console.error("Error fetching screenings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScreeningsData();
  }, [queryStringParameters]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <LoadingElement></LoadingElement>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          md: "65%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px auto",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile={true}
        aria-label="scrollable force tabs example"
        sx={{ width: "100%" }}
        scrollButtons="auto"
        TabScrollButtonProps={{
          sx: {
            color: theme.palette.primary.main,
            visibility: "visible", // Ensure scroll buttons are always visible
            "&.Mui-disabled": {
              opacity: 0.2, // Greyed out when disabled
            },
          },
        }}
      >
        <Tab
          label="All Dates"
          {...a11yProps(0)}
          key={`all_dates_tab_label`}
          sx={{ fontSize: "medium" }}
        />
        {filmsByDate.map(({ date, films }, index) =>
          films.length > 0 ? (
            <Tab
              label={date}
              {...a11yProps(index + 1)}
              key={`${date}_tab_label`}
              sx={{ fontSize: "medium" }}
            />
          ) : (
            <Tab
              label={date}
              {...a11yProps(index + 1)}
              disabled
              key={`${date}_tab_label`}
              sx={{ fontSize: "medium" }}
            />
          )
        )}
      </Tabs>

      <>
        {" "}
        <CustomTabPanel value={value} index={0} key={`all_dates_films`}>
          <AllFilmsOnOneTab films={allFilmsOnAllDates} isForAllDates={true} />
        </CustomTabPanel>
        {filmsByDate.map(({ films, date }, index) => (
          <CustomTabPanel value={value} index={index + 1} key={`${date}_films`}>
            <AllFilmsOnOneTab films={films} isForAllDates={false} />
          </CustomTabPanel>
        ))}
      </>
    </Box>
  );
};
