export const trimDescription = (
  description: string,
  isMobile: boolean
): string => {
  const sentences = description.match(/[^.!?]*[.!?]/g); // Split string into sentences using regex
  const descriptionCharLimit = isMobile ? 150 : 500;

  if (!sentences || sentences.length === 0) {
    return description; // Return original text if no sentences found
  }

  const firstSentence = sentences[0];

  // Check if there is a second sentence
  const secondSentence = sentences[1] || "";

  // If combined length of first and second sentence exceeds 550 chars, return only the first sentence
  if ((firstSentence + secondSentence).length > descriptionCharLimit) {
    return firstSentence.trim();
  }

  // Otherwise, return both sentences
  return firstSentence + " " + secondSentence.trim();
};

export const convertMinutesToHoursAndMinutesString = (
  totalMinutes: number
): string => {
  const hours = Math.floor(totalMinutes / 60); // Get the number of hours
  const minutes = totalMinutes % 60; // Get the remaining minutes

  // Construct the result string based on hours and minutes
  let result = "";
  if (hours > 0) {
    result += `${hours}hr${hours > 1 ? "s" : ""}`; // Add "hr" or "hrs" for plural
  }
  if (minutes > 0) {
    result += `${hours > 0 ? " " : ""}${minutes}min${minutes > 1 ? "s" : ""}`; // Add minutes if present
  }

  return result || "0min"; // Return "0min" if totalMinutes is 0
};
